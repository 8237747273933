<template>
  <div>
    <div class="post bg-red">
      <div class="image">
        <img src="../../assets/team1.jpg">
      </div>
      <div class="post-content-wrapper">
        <div class="post-header">Republic Army (REPA)</div>
        <pre class="post-content">
          Take control of the area in the valley, destroy all small Coalition outposts. Blow up bridges and mine roads to prevent Coalition Forces from sending reinforcements.

          Capture the female assassin, and all nuns for interrogation and to find the laptop.

          Prepare for a coordinated large scale resistance offensive.
        </pre>
      </div>
    </div>

    <div class="post">
      <pre class="post-content-wrapper">
        <div class="post-header">Commander</div>
        <div class="post-content">
          To be announced
        </div>
      </pre>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Infantry</div>
        <pre class="post-content">
          <b>Unit description:</b> Infantry is a military specialization which engages in ground combat on foot.
          <b>Unit size:</b> Maximum 500 soldiers
          <b>Vehicle types:</b> None
          <b>Armband color:</b> RED
          <b>Armband marking:</b> Player Number
          <b>Uniform types:</b> Flecktarn, DPM, Partizan, Flora, OD (Od, Od-Gorka, RG, to be used on all sides), Woodland (to be used on all sides) (US, PL, CCE), Kryptek Highland, Nomad, Mandrake & Pencott Badlands, Sandstorm, Greenzone, M95, Australian DPCU, Belgian woodland, Tigerstripe, M05/M91, Surpat and Tropentarn. Multicam (to be used on all sides)
          <b>Equipment style:</b> Full army camoset and combat gear.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns
          <b>Expected playstyle:</b> Action and military organization
          <b>Physical requirement level:</b> Normal
          <b>LARP elements:</b> None required
          <b>Base & Logistics:</b> Players will have access to Berget provided tents, drinking water, power source 230v for battery charge, toilets and small Kiosk with food for sale.
          <b>Supply of heavy anti tank weapons:</b> medium
          <b>Supply of light tank weapons:</b> medium
          <b>Mandatory equipment to be brought by the player:</b> Player armband (see rules for descriptions), engineers need to bring mines (See rules for description).
          <b>Team equipment provided by Berget:</b> Mobile mortars, TNT charges, BAVS, anti-tank mines.
          <b>Unit special requirements:</b> This unit is not allowed to bring vehicles. Players in this unit are not allowed to wear a Ghillie Suit. This unit is not allowed to bring vehicles. Players in this unit are not allowed to wear a ghillie or half leaf suit.
        </pre>
      </div>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Heavy Mechanized</div>
        <pre class="post-content">
          <b>Unit description:</b> Mechanized are infantry units equipped with armored personnel carriers (APCs) or infantry fighting vehicles (IFVs) for transport and combat.
          <b>Unit size:</b> Maximum 200 soldiers
          <b>Vehicle types:</b> Class 1 - 5
          <b>Armband color:</b> RED
          <b>Armband marking:</b> Player Number
          <b>Uniform types:</b> Flecktarn, DPM, Partizan, Flora, OD (Od, Od-Gorka, RG, to be used on all sides), Woodland (to be used on all sides) (US, PL, CCE), Kryptek Highland, Nomad, Mandrake & Pencott Badlands, Sandstorm, Greenzone, M95, Australian DPCU, Belgian woodland, Tigerstripe, M05/M91, Surpat and Tropentarn. Multicam (to be used on all sides)
          <b>Equipment style:</b> Full army camoset and combat gear.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns
          <b>Expected playstyle:</b> Action
          <b>Physical requirement level:</b> Low
          <b>LARP elements:</b> None required
          <b>Base & Logistics:</b> Players will have access to Berget provided tents, drinking water, power source 230v for battery charge, toilets and small Kiosk with food for sale.
          <b>Supply of heavy anti tank weapons:</b> medium
          <b>Supply of light tank weapons:</b> medium
          <b>Mandatory equipment to be brought by the player:</b> Ingame vehicle or a seat at a friend's vehicle.
          <b>Team equipment provided by Berget:</b>
          <b>Unit special requirements:</b> Players in this unit are not allowed to wear a ghillie or half leaf suit.
        </pre>
      </div>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Sniper & reconnaissance unit</div>
        <pre class="post-content">
          <b>Unit description:</b> A ranger unit supporting friendly forces by reporting in enemy positions, calling in mortar strikes and providing ambushes against enemy units.
          <b>Unit size:</b> Maximum 100 soldiers
          <b>Vehicle types:</b> None
          <b>Armband color:</b> RED
          <b>Armband marking:</b> Player number
          <b>Uniform types:</b> Flecktarn, DPM, Partizan, Flora, OD (Od, Od-Gorka, RG, to be used on all sides), Woodland (to be used on all sides) (US, PL, CCE),Kryptek Highland, Nomad, Mandrake & Pencott Badlands, Sandstorm, Greenzone, M95, Australian DPCU, Belgian woodland, Tigerstripe, M05/M91, Surpat and Tropentarn. Multicam (to be used on all sides)
          <b>Equipment style:</b> Full army camoset and combat gear. Ghillie Suit or half leaf suit.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns.
          <b>Expected playstyle:</b> Milsim
          <b>Physical requirement level:</b> High
          <b>LARP elements:</b> None required
          <b>Base & Logistics:</b> Players will have access to Berget provided tents, drinking water, power source 230v for battery charge, toilets and small Kiosk with food for sale.
          <b>Supply of heavy anti tank weapons:</b> Medium
          <b>Supply of light tank weapons:</b> Medium
          <b>Mandatory equipment to be brought by the player:</b>
          <b>Team equipment provided by Berget:</b>
          <b>Unit special requirements:</b> Previous Berget MILSIM experience is required. This unit is not allowed to bring vehicles. Players in this unit must wear a Ghillie Suit or half leaf suit. This unit will need to be able to survive parts of the game without any logistics provided by BE. Fresh water will however be provided to key objective points for security reasons. Players are allowed to bring their own small green/black tents to set up their own base. Bring equipment to be self-sustaining parts of the game-time when operating in the area.
        </pre>
      </div>
    </div>

    <div class="post-button-wrapper">
      <div @click="$router.back()" class="post-button">Back</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RepublicArmy'
}
</script>

<style scoped>
b { color: #A7C8A0; }
</style>
