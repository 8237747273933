<template>
  <div class="page">
    <div class="segment" v-if="team">
      <div class="content narrow">
        <Resistance v-if="team === 'resistance'" />
        <RepublicArmy v-if="team === 'republicArmy'" />
        <Civilian v-if="team === 'civilians'" />
        <CoalitionForces v-if="team === 'coalitionForces'" />
        <Raiders v-if="team === 'raiders'" />
      </div>
    </div>
    <div class="segment" v-if="!team">

      <div class="content narrow">
        <div class="post column" style="padding:0px;">
          <div class="post-banner">
            <img src="@/assets/pagebanner-teams.jpg">
          </div>
        </div>

        <div class="post bg-yellow big-text">
          <div class="image">
            <img src="../assets/2025/resistance.jpg">
          </div>
          <div class="post-content-wrapper">
            <div class="post-header">Resistance</div>
            <div class="post-content">
              1st marine infantry battalion<br>
              Mechanized & amphibious unit
            </div>
            <div class="post-button-wrapper">
              <div @click="gotoTeam('resistance')" class="post-button">LEARN MORE</div>
            </div>
          </div>
        </div>

        <div class="post bg-red">
          <div class="image">
            <img src="../assets/team1.jpg">
          </div>
          <div class="post-content-wrapper">
            <div class="post-header">Republic Army (REPA)</div>
            <div class="post-content">
              Infantry<br>
              Heavy Mechanized<br>
              Sniper & reconnaissance unit
            </div>
            <div class="post-button-wrapper">
              <div @click="gotoTeam('republicArmy')" class="post-button">LEARN MORE</div>
            </div>
          </div>
        </div>

        <div class="post bg-green">
          <div class="image"><img src="../assets/2025/Nuns.webp"></div>
          <div class="post-content-wrapper">
            <div class="post-header">Nuns</div>
            <div class="post-content">
              Nuns
            </div>
            <div class="post-button-wrapper">
              <div @click="gotoTeam('civilians')" class="post-button">LEARN MORE</div>
            </div>
          </div>
        </div>

        <div class="post bg-blue">
          <div class="image"><img src="../assets/team2.jpg"></div>
          <div class="post-content-wrapper">
            <div class="post-header">Coalition forces</div>
            <div class="post-content">
              Infantry<br>
              Heavy Mechanized<br>
              Special forces<br>
              Sniper & reconnaissance unit
            </div>
            <div class="post-button-wrapper">
              <div @click="gotoTeam('coalitionForces')" class="post-button">LEARN MORE</div>
            </div>
          </div>
        </div>

        <div class="post bg-white">
          <div class="image"><img src="../assets/teamRaiders.jpg"></div>
          <div class="post-content-wrapper">
            <div class="post-header">Raiders</div>
            <div class="post-content">
              Raiders
            </div>
            <div class="post-button-wrapper">
              <div @click="gotoTeam('raiders')" class="post-button">LEARN MORE</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import Resistance from '@/views/Teams/Resistance'
import RepublicArmy from '@/views/Teams/RepublicArmy'
import Civilian from '@/views/Teams/Civilian'
import CoalitionForces from '@/views/Teams/CoalitionForces'
import Raiders from '@/views/Teams/Raiders.vue'
export default {
  name: 'teams',
  components: { CoalitionForces, Civilian, RepublicArmy, Resistance, Raiders },
  computed: {
    team () {
      return this.$route.params.team
    }
  },
  methods: {
    gotoTeam (team) {
      this.$router.push('/teams/' + team)
    }
  }
}
</script>

<style scoped>

</style>
