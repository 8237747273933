<template>
  <div>
    <div class="post bg-blue">
      <div class="image"><img src="../../assets/team2.jpg"></div>
      <div class="post-content-wrapper">
        <div class="post-header">Coalition forces</div>
        <div class="post-content">
          Word from your soldiers in the north is that the Republican Army is preparing a surprise attack in the area around the monastery that you have under your control. Prepare to send reinforcements to the monastery valley and repell the any enemy advances. Make sure to evacuate all nuns and civilians.
        </div>
      </div>
    </div>

    <div class="post">
      <pre class="post-content-wrapper">
        <div class="post-header">Commander</div>
        <div class="post-content">
          To be announced
        </div>
      </pre>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Infantry</div>
        <pre class="post-content">
          <b>Unit description:</b> Infantry is a military specialization which engages in ground combat on foot.
          <b>Unit size:</b> Maximum 300 soldiers
          <b>Vehicle types:</b> None
          <b>Armband color:</b> Blue
          <b>Armband marking:</b> Player Number
          <b>Uniform types:</b> Marpat, Digital Woodland , Cadpat, Vegetato, Multicam/MTP, AOR 2, Digital Flora, ATACS FG, M90, M98/M03, M84, M62, M05 and M95, Multicam (to be used on both sides) OD (Od, Od-Gorka, RG, to be used on all sides), Pencott green zone/Pencott wild wood.
          <b>Equipment style:</b> Full army camoset and combat gear.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns
          <b>Expected playstyle:</b> Action
          <b>Physical requirement level:</b> Normal
          <b>LARP elements:</b> None required
          <b>Base & Logistics:</b> Players will have access to Berget provided tents, drinking water, power source 230v for battery charge, toilets and small Kiosk with food for sale.
          <b>Supply of heavy anti tank weapons:</b> medium
          <b>Supply of light tank weapons:</b> medium
          <b>Mandatory equipment to be brought by the player:</b> Player armband (see rules for descriptions)
          <b>Team equipment provided by Berget:</b>
          <b>Unit special requirements:</b> This unit is not allowed to bring vehicles. Players in this unit are not allowed to wear a Ghillie Suit. Players in this unit are not allowed to wear a ghillie or half leaf suit.
        </pre>
      </div>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Heavy Mechanized</div>
        <pre class="post-content">
          <b>Unit description:</b> Mechanized are infantry units equipped with armored personnel carriers (APCs) or infantry fighting vehicles (IFVs) for transport and combat.
          <b>Unit size:</b> Maximum 200 soldiers
          <b>Vehicle types:</b> Class 1 - 5
          <b>Armband color:</b> Blue
          <b>Armband marking:</b> Player Number
          <b>Uniform types:</b> Marpat, Digital Woodland , Cadpat, Vegetato, Multicam/MTP, AOR 2, Digital Flora, ATACS FG, M90, M98/M03, M84, M62, M05 and M95, Multicam (to be used on both sides) OD (Od, Od-Gorka, RG, to be used on all sides), Pencott green zone/Pencott wild wood
          <b>Equipment style:</b> Full army camoset and combat gear.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns
          <b>Expected playstyle:</b> Action
          <b>Physical requirement level:</b> Low
          <b>LARP elements:</b> None require
          <b>Base & Logistics:</b> Players will have access to Berget provided tents, drinking water, power source 230v for battery charge, toilets and small Kiosk with food for sale.
          <b>Supply of heavy anti tank weapons:</b> medium
          <b>Supply of light tank weapons:</b> medium
          <b>Mandatory equipment to be brought by the player:</b> Ingame vehicle or a seat at a friend's vehicle.
          <b>Team equipment provided by Berget:</b> None
          <b>Unit special requirements:</b> Players in this unit are not allowed to wear a ghillie or half leaf suit.
        </pre>
      </div>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Special forces</div>
        <pre class="post-content">
          <b>Unit description:</b> Special forces are military units trained to conduct special operations behind enemy lines. Special forces may perform functions including airborne operations, counter-insurgency, counter-terrorism & hostage rescue.
          <b>Unit size:</b> Maximum 50 soldiers
          <b>Vehicle types:</b> None
          <b>Armband color:</b> Blue
          <b>Armband marking:</b> Player number + Special forces Armband
          <b>Uniform types:</b> Multicam tropic
          <b>Equipment style:</b> Full army camoset and combat gear. Ghillie Suit or half leaf suit.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns.
          <b>Expected playstyle:</b> Milsim
          <b>Physical requirement level:</b> High
          <b>LARP elements:</b> None required
          <b>Base & Logistics:</b> none
          <b>Supply of heavy anti tank weapons:</b> High
          <b>Supply of light tank weapons:</b> Medium
          <b>Mandatory equipment to be brought by the player:</b>
          <b>Team equipment provided by Berget:</b>
          <b>Unit special requirements:</b> This Unit is a hardcore Milsim unit, and in order to ensure that players in this unit are on the same high level of physical strength and stamina there will be a series of tests to be allowed into this unit.
          Players who booked a ticket in this team will be asked to answer a few questions regarding their experience, and it will be followed up by a physical test in the safe-zone before the game. (Equal to Cooper test) More information will follow.  We are especially looking for players with good mental strength and military tactical experience. Wildlife and hiking experience are also meritorious. You will only have one chance on the physical test in safe-zone, and if you fail you will be moved to the blue infantry unit with others who failed. (We will not reveal who failed the test) If you are a team that wants to join this unit, all members of the team need to pass the tests. Keep this in mind when preparing for Berget. Players are allowed to bring their own small green/black tents to set up their own base. Bring equipment to be self-sustaining parts of the game when operating in the area.

          Players in this unit are not allowed to bring vehicles.
          This unit has access to mines, TNT and Mineworms to instantly clear a large number of mines.
          Players in this unit should NOT book any B.A.V.S (Anti-Tank Weapons), they will be supplied with special B.A.V.S directly from BE. This unit will not start in the game area, they will be starting the game outside of the area and need to make their way into the AO by map and compass. All players who pass the tests and are accepted into this unit will receive a specially made Special Forces patch to be worn with your armband. NOTE: Special forces who have been accepted into the unit the previous year will not need to do the test.
        </pre>
      </div>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Ranger & reconnaissance unit</div>
        <pre class="post-content">
          <b>Unit description:</b> A ranger unit supporting friendly forces by reporting in enemy positions, calling in mortar strikes and providing ambushes against enemy units.
          <b>Unit size:</b> Maximum 100 soldiers
          <b>Vehicle types:</b> None
          <b>Armband color:</b> Blue
          <b>Armband marking:</b> Player number
          <b>Uniform types:</b> Marpat, Digital Woodland , Cadpat, Vegetato, Multicam/MTP, AOR 2, Digital Flora, ATACS FG, M90, M98/M03, M84, M62, M05 and M95, Multicam (to be used on both sides) OD (Od, Od-Gorka, RG, to be used on all sides), Pencott green zone/Pencott wild wood
          <b>Equipment style:</b> Full army camoset and combat gear. Ghillie Suit or half leaf suit.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns.
          <b>Expected playstyle:</b> Milsim
          <b>Physical requirement level:</b> High
          <b>LARP elements:</b> None required
          <b>Base & Logistics:</b> Players will have access to Berget provided tents, drinking water, power source 230v for battery charge, toilets and small Kiosk with food for sale.
          <b>Supply of heavy anti tank weapons:</b> Medium
          <b>Supply of light tank weapons:</b> Medium
          <b>Mandatory equipment to be brought by the player:</b>
          <b>Team equipment provided by Berget:</b>
          <b>Unit special requirements:</b> Previous Berget MILSIM experience is required. This unit is not allowed to bring vehicles. Players in this unit must wear a Ghillie Suit or half leaf suit. This unit will need to be able to survive parts of the game without any logistics provided by BE. Fresh water will however be provided to key objective points for security reasons. Players are allowed to bring their own small green/black tents to set up their own base. Bring equipment to be self-sustaining parts of the game-time when operating in the area.
        </pre>
      </div>
    </div>

    <div class="post-button-wrapper">
      <div @click="$router.back()" class="post-button">Back</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoalitionForces'
}
</script>

<style scoped>
b { color: #A7C8A0; }
</style>
