<template>
  <div>
    <div class="post bg-green">
      <div class="image"><img src="../../assets/2025/Nuns.webp"></div>
      <div class="post-content-wrapper">
        <div class="post-header">Nuns</div>
        <pre class="post-content">
          The nuns in this unit must do everything to protect their sisters and their convent. A neutral unit that wants to avoid violence in all situations. High risk of being captured by other factions.
          Note! This faction is invite only, you can not buy tickets to this faction.
        </pre>
      </div>
    </div>

    <div class="post">
      <pre class="post-content-wrapper">
        <div class="post-header">Commander</div>
        <div class="post-content">
          To be announced
        </div>
      </pre>
    </div>
    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Nuns</div>
        <pre class="post-content">
          <b>Unit description:</b> Players in this unit need to defend their monastery against any sinners. They must avoid being captured and taken as prisoners.
          <b>Unit size:</b> Maximum 30
          <b>Vehicle types:</b> Class 1
          <b>Armband color:</b> Green/white
          <b>Armband marking:</b> ID number
          <b>Uniform types:</b> Civilian, nun outfit black and white.
          <b>Equipment style:</b> Nun, crucifix
          <b>Armament:</b> unknown
          <b>Expected playstyle:</b> Larp.
          <b>Physical requirement level:</b> High
          <b>LARP elements:</b> Yes
          <b>Base & Logistics:</b> Players will have access to Berget tents, drinking water, power source 230v for battery charge & toilets.
          <b>Supply of heavy anti tank weapons:</b> unknown
          <b>Supply of light tank weapons:</b> unknown
          <b>Mandatory equipment to be brought by the player:</b> Player armband (see rules for descriptions). Nun outfit.
          <b>Team equipment provided by Berget:</b> Other Larp items
          <b>Unit special requirements:</b> Players in this unit may be forced to wear a GPS tracker at all time. Players in this unit are not allowed to wear a ghillie or half leaf suit. Players in this unit must endure being captured and interrogated for long periods of time and cannot go off game to escape capture.
        </pre>
      </div>
    </div>

    <div class="post-button-wrapper">
      <div @click="$router.back()" class="post-button">Back</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Civilian'
}
</script>

<style scoped>
b { color: #A7C8A0; }
</style>
