<template>
  <div>
    <div class="post bg-green">
      <div class="image"><img src="../../assets/teamRaiders.jpg"></div>
      <div class="post-content-wrapper">
        <div class="post-header">Raiders</div>
        <pre class="post-content">
          Catching the scent of opportunity, the raiders see their chance to profit from being the first to capture and sell the hiding nuns to the highest bidder.

          Organized criminals who make a living from drug trafficking, arms smuggling, kidnappings and contract killings. Living as nomads as they are wanted and hunted by the authorities and the military, they often change haunts and hiding places. Highly mobile and have access to light mechanized vehicles and modern weapons. Many members are ex-military.

          Parts of Raiders are neutral and can run legitimate businesses such as taxis, shop owners or hunters. Interaction with them can be fruitful as these are always prepared to perform a service for money.
        </pre>
      </div>
    </div>

    <div class="post">
      <pre class="post-content-wrapper">
        <div class="post-header">Commander</div>
        <div class="post-content">
          To be announced
        </div>
      </pre>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Raiders</div>
        <pre class="post-content">
          <b>Unit size:</b> Maximum 50 players
          <b>Vehicle types:</b> Civilian low class vehicles such as ATVs, dirtbikes and pickup trucks. Vehicle class 1.
          <b>Armband color:</b> Black & white
          <b>Armband marking:</b> Player Number
          <b>Uniform types:</b> Civilian clothing, Civilian gang clothes and/or MC clothes combined with any uniform type besides Multicam Tropic..
          <b>Equipment style:</b> Civilian clothing, PMC, Mixed civilian & military clothes. MC clothes.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns
          <b>Expected playstyle:</b> Action & Larp
          <b>Physical requirement level:</b> Normal
          <b>LARP elements:</b> Yes
          <b>Base & Logistics:</b> Drinking water, power source 230v for battery charge (In safezone).
          <b>Supply of heavy anti tank weapons:</b> Low
          <b>Mandatory equipment to be brought by the player:</b> Atv, pickup, mc or dirtbike. Or have a seat in a teammates vehicle
          <b>Team equipment provided by Berget:</b> Berget dollars to all players. Some light anti-tank weapons. Contraband and other Larp items will be handed out.
          <b>Unit special requirements:</b> All players in this team must bring their own vehicle or secure a seat in/on a teammate's vehicle. Bring your own tent sleeping and hygiene equipment, your team mave change base location at any time and can use civilian tents.
        </pre>
      </div>
    </div>

    <div class="post-button-wrapper">
      <div @click="$router.back()" class="post-button">Back</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Raiders'
}
</script>

<style scoped>
b { color: #A7C8A0; }
</style>
